// extracted by mini-css-extract-plugin
export var bg = "PageHeader-module--bg---RzSE";
export var current = "PageHeader-module--current--E39Qu";
export var homeLink = "PageHeader-module--homeLink--+jz1N";
export var languageSwitcher = "PageHeader-module--languageSwitcher--Py1Zx";
export var logo = "PageHeader-module--logo--8hnqt";
export var subMenu = "PageHeader-module--subMenu--brXaF";
export var title = "PageHeader-module--title--t7lV4";
export var topBar = "PageHeader-module--topBar--ASeAc";
export var vignette = "PageHeader-module--vignette--HNW4M";
export var wrapper = "PageHeader-module--wrapper--dbLCs";