import React from "react"

const MarkdownHTML = ({ data, name }) => {
  
    const mdNode = data.allMarkdownRemark.nodes.find(
        node => node.frontmatter.name === name
      );

    if (!mdNode) {
        console.error(`Content not found for: ${name}`)
        return <span></span>;
    }


  
    return (
    <section
      dangerouslySetInnerHTML={{
        __html: mdNode.html,
      }}
    />
  )
}

export default MarkdownHTML
