import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { StaticImage } from "gatsby-plugin-image"
import { Link, useI18next, useTranslation } from "gatsby-plugin-react-i18next"
import React from "react"

interface Props {
  title: string
  section: string
}

import * as styles from "./PageHeader.module.scss"

const PageHeader: React.FC<Props> = ({ title, section }) => {
  const { languages, changeLanguage, language, path, originalPath } = useI18next()
  const { t } = useTranslation();

  const data = useStaticQuery(
    graphql`
      query {
        desktop: allFile(filter: { relativePath: { regex: "/^sections/" } }) {
          nodes {
            name
            childImageSharp {
              fluid(quality: 60, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    `
  )

  // Set ImageData.
  const imageData = data.desktop.nodes.find(f => f.name === section)
    .childImageSharp.fluid

  return (
    <div className={styles.wrapper}>
      <div className={styles.homeLink}><Link to="/">Home</Link></div>
      <div className={styles.languageSwitcher}>
        {languages
          .filter(lang => lang !== language)
          .map(lang => (
            <a
              href="#"
              key={lang}
              onClick={e => {
                e.preventDefault()
                changeLanguage(lang)
              }}
            >
              {lang}
            </a>
          ))}
      </div>

      <StaticImage
        src="../../images/logo.png"
        loading="eager"
        width={350}
        quality={95}
        sizes="(min-width: 600px) 350px, 150px"
        formats={["auto", "webp", "avif"]}
        alt="RedFox enduro academy"
        className={styles.logo}
      />

      <div className={styles.topBar}></div>
      <BackgroundImage
        Tag="div"
        className={styles.bg}
        fluid={imageData}
        backgroundColor={`#040e18`}
      >
        <div className={styles.vignette}></div>
        <h1 className={styles.title}>{title}</h1>
      </BackgroundImage>

      <div className={styles.subMenu}>
        <div className={originalPath === `/${section}/` && styles.current}>
          <Link to={`/${section}/`}>{t('Info')}</Link>
        </div>
        <div className={originalPath === `/${section}/photos` && styles.current}>
          <Link to={`/${section}/photos`}>{t('Photos')}</Link>
        </div>
      </div>
    </div>
  )
}

export default PageHeader
